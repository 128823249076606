import {useEffect, useRef} from "react";

interface props {
    title: string,
    imgSrc: string,
    description: string,
}

const deviceIsAPhone = window.innerWidth < 750;
const Service = ({title, imgSrc, description}: props) => {
    const myRef = useRef<null | HTMLDivElement>(null);

    function handleClickOnService(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (deviceIsAPhone) {
            event.currentTarget.children[0].classList.toggle("service-active");
        }
    }

    useEffect(() => {
        console.log(window.innerWidth)
        if (deviceIsAPhone) {
            const observer = new IntersectionObserver((entries) => {
                const entry = entries[0];
                const target = entry.target;
                target.children[0].classList.add('service-active');
                console.log(entry)
                if (!entry.isIntersecting) {
                    target.children[0].classList.remove('service-active');
                }
            }, {threshold: 1})
            if (myRef.current) {
                observer.observe(myRef.current)
            }
            return () => observer.disconnect();
        }
    }, []);

    return (
        <div className={deviceIsAPhone ? "landing-services-service" : "landing-services-service-desktop"} ref={myRef}
             onClick={handleClickOnService}>
            <div className="landing-services-service-container">
                <div className={'loading-services-service-front'} style={{backgroundImage: `url(${imgSrc})`}}></div>

                <div className={'loading-services-service-back'}>
                    <div className={'loading-services-service-content'}>
                        <h3>
                            {title}
                        </h3>
                        <p className={"landing-services-service-info"}>
                            {description}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service;
