import "../filter.css"
import {ReactComponent as Flecha} from "../../../../../assets/images/flecha.svg"
import {map} from "jquery";
import category from "./Category";
import {useRef} from "react";
import {debug} from "node:util";
import {filters} from "../../../../../utils/filters";


interface props {
    categoryName: string,
    tags: { [key: string]: any },
    inputValues: { name: string, category: string, tag: string }
    setInputValues: React.Dispatch<React.SetStateAction<{ name: string, category: string, tag: string }>>
}

const CatalogueFilterCategory = ({categoryName, tags, inputValues, setInputValues}: props) => {

    const isOpen = categoryName === inputValues.category;
    const containerRef = useRef<HTMLUListElement | null>(null);

    function handleHeaderOnClick() {
        if (isOpen) {
            setInputValues({...inputValues, category: ""})
        } else {
            setInputValues({...inputValues, category: categoryName})
        }
    }

    function handleTagOnClick(tag: string) {
        console.log("Clicked on the tag", tag)
        if (inputValues.tag === tag) {
            setInputValues({...inputValues, tag: ""})
        } else {
            setInputValues({...inputValues, tag: tag})
        }
    }

    return (
        <div className={"catalogue-filter-category"}>
            <div className={"catalogue-category-header"}
                 onClick={handleHeaderOnClick}>
                <h3>{categoryName}</h3>
                <Flecha
                    className={isOpen ? "catalogue-category-arrow catalogue-category-arrow-active" : "catalogue-category-arrow"}/>
            </div>
            <ul className={isOpen ? "catalogue-category-body catalogue-category-body-active" : "catalogue-category-body"}
                ref={containerRef}>
                {Object.keys(tags).map(tag => (
                    <li key={tags[tag]}>

                        <input type="radio" checked={inputValues.tag === tag} value={tags[tag]} name={tags[tag]}
                               id={tags[tag]}
                               onClick={() => {
                                   handleTagOnClick(tag)
                               }
                               }/>

                        <label htmlFor={tags[tag]}> {tags[tag]} </label>
                    </li>
                ))}

            </ul>
        </div>
    )
}

export default CatalogueFilterCategory;