import React, {createContext, useContext, useEffect, useId, useRef, useState} from 'react';
import {Link, Outlet, useOutletContext, useLoaderData, ScrollRestoration, useLocation} from "react-router-dom";
import ESRLogo from "./assets/images/landing/logoESR.webp"
import './components/navbar/navbar.css';
import "./assets/styles/App.css"
import DesktopNavbar from "./components/navbar/DesktopNavbar";
import PhoneNavbar from "./components/navbar/PhoneNavbar";
import {CarritoDeCompras} from "./utils/CarritoDeCompras";
import ProductInShoppingCar from "./types/ProductInShoppingCar";
import Footer from "./components/footer/Footer";
import {exchangeRoutine, getExchangeFromApi, getStoredExchangeRate, updateExchangeRate} from "./utils/ExchangeUtility";
import SpotifyPlayer from "./components/reusable/SpotifyPlayer";

export let CarritoContext = createContext<ProductInShoppingCar[]>(CarritoDeCompras.getCarrito());


export const NUMBER_OF_DECIMALS = 2;
exchangeRoutine();
console.log(getStoredExchangeRate());
const App: React.FC = () => {
    const location = useLocation()
    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) element.scrollIntoView({behavior: "smooth"});
        }
    }, [location]);

    return (
        <div className={"app"}>
            <ScrollRestoration/>
            <DesktopNavbar/>
            <SpotifyPlayer key={"SpotifyPlayer"}/>
            <PhoneNavbar/>
            <div className={"overlay"}>
                <div className={"background-img"}></div>
            </div>
            <Outlet/>
            <Footer/>
        </div>
    );
};


export default App;
