import {Link} from "react-router-dom";
import React from "react";

interface props {
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductOptions = ({setMenuOpen}: props) => {

    return (
        <ul className={"phone-menu-pages-list"}>
            <span className={"phone-menu-link-tittle"}>Productos</span>
            <li>
                <Link className={"phone-menu-link finding"} to={"/#destacados"}
                      onClick={() => setMenuOpen(false)}>Destacados</Link>
            </li>
            <li>
                <Link className={"phone-menu-link"} to={"/productos"}
                      onClick={() => setMenuOpen(false)}>Catálogo</Link>
            </li>
        </ul>
    )
}

export default ProductOptions;