import {Link} from "react-router-dom";
import React from "react";

interface props {
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SectionList = ({setMenuOpen}:props) => {

    return (
        <ul className={"phone-menu-link-list"}>
            <span className={"phone-menu-link-tittle"}>Secciones</span>
            <li>
                <a className={"phone-menu-link"} onClick={() => setMenuOpen(false)} href="#main">Inicio</a>
            </li>
            <li>
                <a className={"phone-menu-link"} onClick={() => setMenuOpen(false)}
                      href="#destacados">Productos destacados</a>
            </li>
            <li>
                <a className={"phone-menu-link"} onClick={() => setMenuOpen(false)}
                   href="#servicios">Servicios</a>
            </li>
            <li>
                <a className={"phone-menu-link"} onClick={() => setMenuOpen(false)}
                   href="#nosotros">Nosotros</a>
            </li>
        </ul>
    )
}

export default SectionList;