import React, {useEffect, useState} from "react";

interface props {
    setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isMenuOpen: boolean;
}

const BurgerIconBtn = ({setIsMenuOpen, isMenuOpen}: props) => {


    return (
        <div className={isMenuOpen ? "slide-menu-button menu-button-active" : "slide-menu-button"} onClick={() => {
            setIsMenuOpen(!isMenuOpen)
        }}>
            <div className={isMenuOpen ? "menu-icon-stroke menu-stroke-active" : "menu-icon-stroke"}></div>
            <div className={isMenuOpen ? "menu-icon-stroke menu-stroke-active" : "menu-icon-stroke"}></div>
            <div className={isMenuOpen ? "menu-icon-stroke menu-stroke-active" : "menu-icon-stroke"}></div>
        </div>

        /*<button onClick={() => setIsMenuOpen(!isMenuOpen)} className={"slide-menu-button"}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                 stroke="currentColor" className="menu-icon">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
            </svg>
        </button>*/
    )
}

export default BurgerIconBtn;