import "./filter.css"
import CatalogueFilterCategory from "./fragments/CatalogueFilterCategory";
import {filters} from "../../../../utils/filters";
import React, {useEffect} from "react";
import {ReactComponent as IconoLupa} from "../../../../assets/images/lupa.svg"
import CatalogueInputFilter from "./fragments/CatalogueInputFilter";


interface props {
    activeFilter: { name: string, tag: string, category: string }
    setActiveFilter: React.Dispatch<React.SetStateAction<{ name: string, tag: string, category: string }>>
}

const Filter = ({activeFilter, setActiveFilter}: props) => {


    useEffect(() => {
        console.log("activeFilter", activeFilter.tag);
        if (activeFilter.tag) setActiveFilter({...activeFilter, tag: activeFilter.tag})
    }, [activeFilter.tag]);

    return (
        <aside className={"catalogue-filter"}>
            <h2>Filtros</h2>

            <CatalogueInputFilter setActiveFilter={setActiveFilter} activeFilter={activeFilter}/>

            <IconoLupa className={"catalogue-filter-search-icon phone"}/>

            <CatalogueFilterCategory categoryName={"Especies"}
                                     tags={filters.species}
                                     inputValues={activeFilter} setInputValues={setActiveFilter}
                                     key={"specie-filter"}/>

            <CatalogueFilterCategory categoryName={"Laboratorio"}
                                     tags={filters.laboratories}
                                     inputValues={activeFilter} setInputValues={setActiveFilter}
                                     key={"laboratory-filter"}/>

            <CatalogueFilterCategory categoryName={"Línea del producto"}
                                     tags={filters.productLine}
                                     inputValues={activeFilter} setInputValues={setActiveFilter}
                                     key={"productLine-filter"}/>
        </aside>
    )
}

export default Filter;