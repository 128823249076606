import Service from "./fragments/Service";
import asistenciaTecnica from '../../../../assets/images/landing/asistencia-tecnica.webp'
import capacitacionesTecnicas from '../../../../assets/images/landing/capacitaciones-tecnicas.webp'
import controlDeCalidad from '../../../../assets/images/landing/control-de-calidad-del-agua.webp'
import monitoreoEnGranja from '../../../../assets/images/landing/monitoreo-en-granja.webp'
import monitoreoBromatologico from '../../../../assets/images/landing/monitoreo-bromatologico.webp'
import {useEffect, useId, useRef, useState} from "react";

const messages = [
    'Análisis en campo de contenido nutricional de alimentos y materias primas para su óptima utilización, utilizando tecnología Holandesa, traída a nosotros por nuestro proveedor Trouw Nutrition.',
    'Apoyo con asesor en sanidad, producción y manejo para mejorar los parámetros productivos de las granjas, así como la formulación y optimización de raciones.',
    'Pláticas sobre problemas en granja, productos y habilidades blandas necesarias para mejorar el desempeño de áreas puntuales, impartidas por los mejores técnicos proporcionados por nuestros proveedores y organizadas por SNI.',
    'Las auditorías en plantas de alimento y granjas, junto con visitas en campo, mejoran los protocolos de control de calidad y ofrecen recomendaciones clave en bioseguridad, sanidad y gestión de producción.',
    'Apoyo con sangrados, necropsias y toma de fluidos, análisis de micotoxinas.'


]
const ServicesSection = () => {


    return (
        <section className={"landing-service-section"} id={"servicios"}>
            <h2 className={'landing-page-section-tittle'}>Servicios</h2>
            <div className={'landing-services-container'}>
                <Service title={'Monitoreo Bromatológico'} description={messages[0]} imgSrc={monitoreoBromatologico}
                         key={useId()}/>
                <Service title={'Asistencia técnica'} description={messages[1]} imgSrc={asistenciaTecnica}
                         key={useId()}/>
                <Service title={'Capacitaciones técnicas'} description={messages[2]} imgSrc={capacitacionesTecnicas}
                         key={useId()}/>
                <Service title={'Apoyo en control de calidad'} description={messages[3]} imgSrc={controlDeCalidad}
                         key={useId()}/>
                <Service title={'Monitoreo en granja'} description={messages[4]} imgSrc={monitoreoEnGranja}
                         key={useId()}/>
            </div>
        </section>
    )
}

export default ServicesSection;