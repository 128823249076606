import React from "react";

interface props {
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const PagesList = ({setMenuOpen}: props) => {

    function handleClick(href: string) {
        setMenuOpen(false);
        window.location.href = href;
    }

    return (
        <ul className={"phone-menu-link-list"}>
            <span className={"phone-menu-link-tittle"}>Secciones</span>
            <li>
                <a className={"phone-menu-link"} onClick={() => handleClick("/#main")}>Inicio</a>
            </li>
            <li>
                <a className={"phone-menu-link"}
                   onClick={() => handleClick("/#servicios")}>Servicios</a>
            </li>
            <li>
                <a className={"phone-menu-link"}
                   onClick={() => handleClick("/#nosotros")}>Nosotros</a>
            </li>
        </ul>
    )
}

export default PagesList;